import { loadView, loadWidget } from '@/utils/helpers';

const routes = [
  {
    path: '/',
    redirect: '/signin',
  },
  {
    path: '/signin',
    name: 'accounts.signin',
    component: loadView('greeters/Login'),
    meta: {
      pageTitle: 'Sign In',
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'accounts.forgot-password',
    component: loadView('greeters/ForgotPassword'),
    meta: {
      pageTitle: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'accounts.reset-password',
    component: loadView('greeters/ResetPassword'),
    meta: {
      pageTitle: 'Reset Password',
      requiresAuth: false,
    },
  },
  {
    path: '/verification/:code',
    name: 'accounts.verification',
    component: loadView('greeters/AccountVerification'),
    meta: {
      pageTitle: 'Account Verification',
      requiresAuth: false,
    },
  },
  {
    path: '/actions/success',
    name: 'accounts.actions.success',
    component: loadWidget('pages/SuccessPage'),
    meta: {
      pageTitle: 'Success Page',
      requiresAuth: false,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: loadView('Master'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: loadView('pages/dashboard/Index'),
        meta: {
          pageTitle: 'Home',
          requiresAuth: true,
        },
      },
      {
        path: '/products',
        name: 'products',
        component: loadView('pages/products/Index'),
        meta: {
          pageTitle: 'Products',
          requiresAuth: true,
        },
      },
      {
        path: '/products/add',
        name: 'products.add',
        component: loadView('pages/products/Create'),
        meta: {
          pageTitle: 'Add Product',
          requiresAuth: true,
        },
      },
      {
        path: '/products/:id/edit',
        name: 'products.edit',
        component: loadView('pages/products/Edit'),
        meta: {
          pageTitle: 'Edit Product',
          requiresAuth: true,
        },
      },
      {
        path: '/ussd/service-codes',
        name: 'ussd.service.codes',
        component: loadView('pages/ussd-codes/Index'),
        meta: {
          pageTitle: 'USSD Service Codes',
          requiresAuth: true,
        },
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: loadView('pages/transactions/Transactions'),
        meta: {
          pageTitle: 'Transactions',
          requiresAuth: true,
        },
      },
      {
        path: '/payments',
        name: 'payments',
        component: loadView('pages/payments/AllPayments'),
        meta: {
          pageTitle: 'All Payments',
          requiresAuth: true,
        },
      },
      {
        path: '/commissions',
        name: 'commissions',
        component: loadView('pages/commissions/AllCommissions'),
        meta: {
          pageTitle: 'All Commissions',
          requiresAuth: true,
        },
      },
      {
        path: '/payment-methods',
        name: 'payment-methods',
        component: loadView('pages/payments/PaymentMethod'),
        meta: {
          pageTitle: 'Payment Methods',
          requiresAuth: true,
        },
      },
      {
        path: '/deliveries/in-transit',
        name: 'deliveries.in-transit',
        component: loadView('pages/tracking/InTransit'),
        meta: {
          pageTitle: 'En-Route Deliveries',
          requiresAuth: true,
        },
      },
      {
        path: '/deliveries/completed',
        name: 'deliveries.completed',
        component: loadView('pages/tracking/Completed'),
        meta: {
          pageTitle: 'Completed Deliveries',
          requiresAuth: true,
        },
      },
      {
        path: '/disputes',
        name: 'disputes',
        component: loadView('pages/transactions/Transactions'),
        meta: {
          pageTitle: 'Transactions',
          requiresAuth: true,
        },
      },
      {
        path: '/archives',
        name: 'archives',
        component: loadView('pages/Archives'),
        meta: {
          pageTitle: 'Archives',
          requiresAuth: true,
        },
      },
      {
        path: '/trash',
        name: 'trash',
        component: loadView('pages/Trash'),
        meta: {
          pageTitle: 'Trash',
          requiresAuth: true,
        },
      },
      // SMS Routes
      {
        path: '/products/sms/dashboard',
        name: 'sms.dashboard',
        component: loadView('pages/products/sms/Dashboard'),
        meta: {
          pageTitle: 'SMS Dashboard',
          requiresAuth: true,
        },
      },
      {
        path: '/products/sms/customers',
        name: 'sms.customers',
        component: loadView('pages/products/sms/customers/Index'),
        meta: {
          pageTitle: 'All Customers',
          requiresAuth: true,
        },
      },
      {
        path: '/products/sms/customers/logs',
        name: 'sms.customers.logs',
        component: loadView('pages/products/sms/logs/Index'),
        meta: {
          pageTitle: 'Customer Logs',
          requiresAuth: true,
        },
      },
      {
        path: '/products/sms/sender-ids/all',
        name: 'sms.sender-id.all',
        component: loadView('pages/products/sms/sender-ids/AllSenderIDs'),
        meta: {
          pageTitle: 'All Sender IDs',
          requiresAuth: true,
        },
      },
      {
        path: '/products/sms/logs',
        name: 'sms.logs',
        component: loadView('pages/products/sms/logs/Index'),
        meta: {
          pageTitle: 'All Logs',
          requiresAuth: true,
        },
      },
      {
        path: '/products/cu/dashboard',
        name: 'cu.dashboard',
        component: loadView('pages/products/cu/Dashboard'),
        meta: {
          pageTitle: 'CU Dashboard',
          requiresAuth: true,
        },
      },
      {
        path: '/products/cu/customers',
        name: 'cu.customers',
        component: loadView('pages/products/cu/customers/Index'),
        meta: {
          pageTitle: 'All Credit Unions',
          requiresAuth: true,
        },
      },
      {
        path: '/products/cu/transactions',
        name: 'cu.transactions',
        component: loadView('pages/products/cu/Transactions'),
        meta: {
          pageTitle: 'All Transactions',
          requiresAuth: true,
        },
      },
      {
        path: '/products/cu/customers/add',
        name: 'cu.customers.add',
        component: loadView('pages/products/cu/customers/Create'),
        meta: {
          pageTitle: 'Create New Organization',
          requiresAuth: true,
        },
      },
      {
        path: '/products/cu/ussd/access/counts',
        name: 'cu.ussd.access-counts',
        component: loadView('pages/products/cu/AccessCounts'),
        meta: {
          pageTitle: 'USSD Access Count',
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: '404',
    component: loadWidget('pages/Error404'),
  },
];

export default routes;
